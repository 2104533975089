@font-face {
  font-family: 'Geist';
  src: url('/src/assets/fonts/Geist-Thin.woff2') format('woff2'),
    url('/src/assets/fonts/Geist-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}


@font-face {
  font-family: 'Geist';
  src: url('/src/assets/fonts/Geist-Light.woff2') format('woff2'),
    url('/src/assets/fonts/Geist-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('/src/assets/fonts/Geist-Regular.woff2') format('woff2'),
    url('/src/assets/fonts/Geist-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('/src/assets/fonts/Geist-Medium.woff2') format('woff2'),
    url('/src/assets/fonts/Geist-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('/src/assets/fonts/Geist-SemiBold.woff2') format('woff2'),
    url('/src/assets/fonts/Geist-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('/src/assets/fonts/Geist-Bold.woff2') format('woff2'),
    url('/src/assets/fonts/Geist-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('/src/assets/fonts/Geist-Black.woff2') format('woff2'),
    url('/src/assets/fonts/Geist-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}