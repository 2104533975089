@import '../styles/fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/*  */

.scrollbar-w-1::-webkit-scrollbar {
  width: 0.225rem;
  height: 0.225rem;
}

.scrollbar-w-2::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

.scrollbar-w-4::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.scrollbar-track-yellow-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-yellow::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: rgb(128, 128, 128);
  background-color: rgba(128, 128, 128, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

/*  */

.slide {
  -webkit-animation: slide .6s cubic-bezier(0.2, 0.6, 0.2, 1);
  -moz-animation: slide .6s cubic-bezier(0.2, 0.6, 0.2, 1);
  animation: slide .6s cubic-bezier(0.2, 0.6, 0.2, 1);
  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

.delay01 {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

.delay02 {
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
}

.delay03 {
  -webkit-animation-delay: .9s;
  animation-delay: .9s;
}

.work-item__image {
  transition: all .8s cubic-bezier(0.2, 0.6, 0.2, 1);
}

.work-item__image:hover {
  transform: scale(.95);
  transition: all .8s cubic-bezier(0.2, 0.6, 0.2, 1);
  cursor: pointer;
}

.work-item img {
  transition: all .8s cubic-bezier(0.2, 0.6, 0.2, 1);

}

.work-item:hover img {
  transform: scale(1.15);
  transition: all .8s cubic-bezier(0.2, 0.6, 0.2, 1);
  cursor: pointer;
}

/*  */

.video-desktop {
  max-width: 1200px;
  margin: 0 auto;
  padding: 70px 75px;
  background: #DEDEDE;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.video-desktop video {
  position: relative;
  z-index: 2;
  overflow: hidden;
  border-radius: 8px;
}

.video-desktop__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: 70vh;
}

/*  */

@media (min-width:768px) {
  .work-item:nth-child(2n-2) {
    position: relative;
    top: 300px;
  }
}

@media (max-width:768px) {
  .work-item {
    width: 100%;
    margin-bottom: 40px;
  }

  .work-item:nth-child(2n-2) {
    top: 0;
  }

  .work-item__image {
    height: 480px;
  }
}